import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Props as TableProps, Table } from './Table'

export interface Props {
  languageCode: string
  services?: string[]
  tables: TableProps[]
  title?: string
}

export const Tables = memo(function Tables({
  languageCode,
  services,
  tables,
  title,
}: Props) {
  if (!tables) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Wrapper>
        {tables.map((item, index) => (
          <Table key={index} {...item} />
        ))}

        {services ? (
          <>
            <RowTitle>{useVocabularyData('services', languageCode)}</RowTitle>
            <Rows>
              <Row row>
                <Label></Label>
                <Cell>{useVocabularyData('external', languageCode)}</Cell>
                <Cell>{useVocabularyData('members', languageCode)}</Cell>
                <Cell>{useVocabularyData('hotel-guests', languageCode)}</Cell>
              </Row>
              {services.map((item: any, index) => (
                <Row key={index} row stretch>
                  <Label>{item.label}</Label>
                  <Cell>{item.external}</Cell>
                  <Cell>{item.members}</Cell>
                  <Cell>{item.guests}</Cell>
                </Row>
              ))}
            </Rows>
          </>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 8.125rem 11.944vw 0;

  @media (max-width: 1199px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  @media (max-width: 1023px) {
    padding-top: 5.625rem;
  }
`

const Title = styled.h2`
  max-width: 33.75rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin: auto;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Wrapper = styled.div``

const RowTitle = styled.h3`
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight1};
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin: 6.25rem 0 2rem;
  padding-bottom: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin-top: 3.75rem;
  }
`

const Rows = styled.div``

const Row = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-top: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  &:first-of-type {
    background: none;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.0375rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    > div {
      padding-top: 0;
      padding-bottom: 1.25rem;
    }
  }

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    font-size: 0.9375rem;
    line-height: 1.75rem;
    &:first-of-type {
      font-size: 0.6875rem;
      letter-spacing: normal;
      line-height: 1.125rem;
    }
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0.875rem 1.875rem;

  @media (max-width: 1023px) {
    width: 100%;
    border-bottom: 0.125rem solid
      ${({ theme }) => theme.colors.variants.neutralLight2};
    flex: auto;
    padding: 0.75rem 1.25rem;
  }
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.875rem;
  border-left: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  font-weight: 600;
  padding: 0.875rem 1.875rem;

  @media (max-width: 1023px) {
    width: 33.333%;
    padding: 0.75rem 1.25rem;
    &:nth-of-type(2) {
      border-left: 0;
    }
  }
`

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  legend?: string
  row?: string[]
  title?: string
}

export const Table = memo(function Table({
  languageCode,
  legend,
  row,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      {row ? (
        <Rows>
          <Row row>
            <Label></Label>
            <Cell>{useVocabularyData('low-season', languageCode)}</Cell>
            <Cell>{useVocabularyData('mid-season', languageCode)}</Cell>
            <Cell>{useVocabularyData('high-season', languageCode)}</Cell>
          </Row>
          {row.map((item: any, index) => (
            <Row key={index} row stretch>
              <Label>{item.label}</Label>
              <Cell>{item.low_season}</Cell>
              <Cell>{item.mid_season}</Cell>
              <Cell>{item.high_season}</Cell>
            </Row>
          ))}
        </Rows>
      ) : null}

      {legend ? <Legend dangerouslySetInnerHTML={{ __html: legend }} /> : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 6.25rem;
  &:first-of-type {
    margin-top: 9.375rem;
  }

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
    &:first-of-type {
      margin-top: 3.75rem;
    }
  }
`

const Title = styled.h3`
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight1};
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  padding-bottom: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
`

const Rows = styled.div``

const Row = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-top: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  &:first-of-type {
    background: none;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.0375rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    > div {
      padding-top: 0;
      padding-bottom: 1.25rem;
    }
  }

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    font-size: 0.9375rem;
    line-height: 1.75rem;
    &:first-of-type {
      font-size: 0.6875rem;
      letter-spacing: normal;
      line-height: 1.125rem;
    }
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0.875rem 1.875rem;

  @media (max-width: 1023px) {
    width: 100%;
    border-bottom: 0.125rem solid
      ${({ theme }) => theme.colors.variants.neutralLight2};
    flex: auto;
    padding: 0.75rem 1.25rem;
  }
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.875rem;
  border-left: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  font-weight: 600;
  padding: 0.875rem 1.875rem;

  @media (max-width: 1023px) {
    width: 33.333%;
    padding: 0.75rem 1.25rem;
    &:nth-of-type(2) {
      border-left: 0;
    }
  }
`

const Legend = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin-top: 3.75rem;
`

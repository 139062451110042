import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Faq } from 'app/components/Faq'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  accordionsList?: string[]
  image?: ImageProps
  row?: string[]
  title?: string
}

export const Membership = memo(function Membership({
  accordionsList,
  image,
  row,
  title,
}: Props) {
  return (
    <Container row wrap>
      <LeftSide>
        <Logo>
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
        </Logo>
        {title ? (
          <Title
            dangerouslySetInnerHTML={{ __html: title.replace(' ', '<br />') }}
          />
        ) : null}
      </LeftSide>

      <RightSide>
        {row ? (
          <Rows>
            {row.map((item: any, index) => (
              <Row key={index} row stretch>
                <Cell>{item.label}</Cell>
                <Cell>{item.first_column}</Cell>
                <Cell>{item.second_column}</Cell>
              </Row>
            ))}
          </Rows>
        ) : null}

        {accordionsList ? (
          <Faq
            className="membership-accordions"
            items={accordionsList as any}
            variant="large"
          />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 9.0625rem;
  &:first-of-type {
    margin-top: 8.25rem;
  }

  @media (max-width: 1023px) {
    margin-top: 5.625rem;
    &:first-of-type {
      margin-top: 3.75rem;
    }
  }
`

const LeftSide = styled.div`
  width: 19%;
  padding-right: 4.0625rem;
  text-align: center;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 14.375rem;
    margin: 0 auto 1.875rem;
    padding-right: 0;
  }
`

const Logo = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 50%;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
  text-transform: uppercase;
`

const RightSide = styled.div`
  flex: 1;

  .membership-accordions {
    padding: 0;

    > div {
      &:first-of-type {
        margin-top: 1.875rem;
      }
    }
  }

  @media (max-width: 1023px) {
    flex: auto;
  }
`

const Rows = styled.div``

const Row = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-top: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    font-size: 0.9375rem;
    line-height: 1.75rem;
  }
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  border-left: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 0.875rem 1.875rem;
  &:nth-of-type(1) {
    width: 42%;
    font-weight: 600;
  }
  &:nth-of-type(2) {
    width: 42%;
  }
  &:nth-of-type(3) {
    width: 12%;
    font-weight: 600;
    justify-content: center;
  }

  @media (max-width: 1023px) {
    padding: 0.75rem 1.25rem;
    &:nth-of-type(1) {
      width: 100%;
      border-bottom: 0.125rem solid
        ${({ theme }) => theme.colors.variants.neutralLight2};
    }
    &:nth-of-type(2) {
      width: 75%;
    }
    &:nth-of-type(3) {
      width: 25%;
    }
  }
`

import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Membership, Props as MembershipProps } from './Membership'

export interface Props {
  languageCode: string
  memberships: MembershipProps[]
}

export const Memberships = memo(function Memberships({
  languageCode,
  memberships,
}: Props) {
  if (!memberships) {
    return null
  }

  return (
    <Container>
      <Title>{useVocabularyData('membership', languageCode)}</Title>

      <Wrapper>
        {memberships.map((item, index) => (
          <Membership key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 13.375rem 11.944vw 0;

  @media (max-width: 1199px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }

  @media (max-width: 1023px) {
    padding-top: 7.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin: auto;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Wrapper = styled.div``
